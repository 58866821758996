<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>This where magic happens</h1>
        </div>
        <span style="white-space: pre-line">@Model.CommentText</span>
        <form @submit.prevent>
          <div class="col-md-6">
            <div class="form-group">
              <input
                type="text"
                v-model="industry"
                class="form-control"
                placeholder="Industry"
                aria-label="Username"
                aria-describedby="basic-addon1"
                required
              />
              <textarea
                type="text"
                v-model="need"
                class="form-control"
                id="inputArea"
                placeholder="Tell us what you need"
                rows="5"
                cols="40"
                required
              ></textarea>
            </div>
            <button type="submit" @click="submitData" class="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
      <div v-html="generatedText" v-if="generatedText"></div>
    </div>
  </div>
</template>
  
  <script>
import "bootstrap/dist/css/bootstrap.min.css";
import API from "@/js/api";
export default {
  data() {
    return {
      need: "",
      generatedText: "",
      industry: "",
    };
  },

  methods: {
    async submitData() {
      if (this.need && this.industry)
        var response = await API.generateCompletions({
          need: this.need,
          industry: this.industry,
        });
      response.text = response.text.replace(/\n/g,'<br>');
      this.generatedText = response.text;
      console.log(response.text);
    },
  },
};
</script>
  
  <style>
</style>