<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Add the secret sauce</h1>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <textarea
              type="text"
              ref="input"
              v-model="inputData"
              class="form-control"
              id="inputArea"
              placeholder="prompt"
              rows="5"
              cols="40"
            ></textarea>
          </div>
          <button type="submit" @click="submitData" class="btn btn-primary">
            Submit
          </button>
          <button @click="logPrompt" class="btn btn-primary">Log Prompt</button>
        </div>
      </div>
      <p v-if="this.generatedText">{{ this.generatedText }}</p>
    </div>
  </div>
</template>
  
  <script>
import "bootstrap/dist/css/bootstrap.min.css";
import API from "@/js/api";
export default {
  data() {
    return {
      inputData: "",
      generatedText: "",
    };
  },
  methods: {
    async submitData() {
      //alert("Data is: " + this.inputData);
      var response = await API.updatePrompt({
        prompt: this.inputData,
      });
      console.log(response);
      this.generatedText = response.text;
      //window.location.reload();
    },
    async logPrompt() {
      var response = await API.getPrompt();
      this.inputData = response.prompt;
      console.log(response.prompt);
    },
  },
  mounted: async function () {
    var response = await API.getPrompt();
    this.inputData = response.prompt;
  },
};
</script>
  
  <style>
button {
  display: inline-block;
  padding: 7px;
}
</style>